<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 450px"
        placeholder="请输入对应的筛选"
        v-model="search_str"
        class="input-with-select"
      >
        <el-select
          v-model="select_index"
          style="width: 150px"
          slot="prepend"
          placeholder="请选择查询方式"
        >
          <el-option
            v-for="(item, index) in search_sel"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="编辑" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="用户帐号" label-width="120">
              <el-input
                v-model="formamend.username"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" label-width="120">
              <el-input
                v-model="formamend.license_company_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人名字" label-width="120">
              <el-input
                v-model="formamend.contact_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司所属行业" label-width="120">
              <el-cascader
                :props="{ value: 'industry_id', label: 'name' }"
                v-model="industry_name"
                :options="indry"
                @change="indusChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="公司地点" label-width="120">
              <el-cascader
                v-model="place_data"
                :options="city"
                @change="areaChange"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="会员到期时间" name="MemberExpiration">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="会员到期时间">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="form.date"
                style="width: 250px"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="是否种子用户">
              <el-switch
                active-color="#58362A"
                v-model="form.is_seed"
                active-text="种子"
                inactive-text="非种子"
              ></el-switch>
            </el-form-item>

            <el-form-item label="积分兑换商品">
              <el-switch
                active-color="#58362A"
                v-model="isduihuan"
                active-text="不可以"
                inactive-text="可以"
              ></el-switch>
            </el-form-item>

            <el-form-item label="独立经纪人管理">
              <el-switch
                active-color="#58362A"
                v-model="is_gl_agent"
                active-text="有"
                inactive-text="没有"
              ></el-switch>
            </el-form-item>


            <el-form-item label="选择套餐">
              <el-select v-model="g_id" placeholder="选择套餐">
                <el-option
                  v-for="item in group_list"
                  :key="item.id"
                  :label="item.group_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item> </el-form
        ></el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="amend">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  search_resume,
  amend_user,
  amend_date,
} from "@/api/hr24_api/api_all.js";
import { showCity, get_user_group } from "@/api/resume";
import axios from "axios";

export default {
  data() {
    return {
      search_str: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_cell: [
        { title: "帐号", value: "username" },
        { title: "联系人名称", value: "contact_name" },
        { title: "公司名称", value: "license_company_name" },
        { title: "当前积分", value: "integral" },
        { title: "种子客户", value: "is_seed_str" },
        { title: "公司行业", value: "industry_name_str" },
        { title: "公司所在地", value: "place_data_str" },
        { title: "会员开始时间", value: "vip_start_time_str" },
        { title: "会员到期时间", value: "vip_end_time_str" },
        { title: "独立经纪人管理", value: "is_gl_agent" },
        { title: "套餐", value: "group_name" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      isduihuan: false,
      dialogFormVisible: false,
      formamend: {
        username: "",
        contact_name: "",
        license_company_name: [],
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
      group_list: {}, //套餐列表
      g_id: "", //选择套餐id
      is_gl_agent:false,//独立管理经纪人
    };
  },
  mounted() {
    axios.get("/static/industry.json").then((res) => {
      this.indry = res;
    });
    showCity({}).then((res) => {
      console.log("dddddddddd showCity");
      this.city = res.data;
    });
  },
  methods: {
    //上传营业执照
    handleAvatarSuccess(res, file) {
      this.imageUrl = file.response.file_url;
      this.form.license = file.response.file_id;
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      console.log("dddddddddd onSubmit");
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,

        isduihuan: this.isduihuan == true ? 1 : 0,
        g_id: this.g_id,
        is_gl_agent:this.is_gl_agent== true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        console.log("dddddddddd amend_date");
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      console.log("dddddddddd search");

      if (!this.search_str || !this.select_index) {
        this.$message.error("请输入帐号查证");
        return;
      }

      if (this.select_index && this.search_str) {
        let search_data = {
          [this.select_index]: this.search_str,
        };
        search_resume({ search_data }).then((res) => {
          if (!res.code) {
            if (res.data.length) {
              res.data.map((item) => {
                console.log(item);
                item.is_seed_str = item.is_seed ? "是" : "否";
                
                item.is_gl_agent = item.is_gl_agent==1 ? "有" : "没有";


                item.industry_name_str =
                  item.industry_name.second_industry_name;
                item.place_data_str =
                  item.place_data.city_name +
                  "-" +
                  item.place_data.district_name;
              });
            }
            console.log("ssssssssssss");
            console.log(res.data);

            this.tableData = res.data;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          console.log(res);
        });
      }
    },

    redact(e) {
      console.log("123456sssss");
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.user_detail_id = e.user_detail_id;
      this.formamend = {
        username: e.username,
        contact_name: e.contact_name,
        license_company_name: e.license_company_name,
      };
      this.industry_name = [
        e.industry_name.first_industry_id,
        e.industry_name.second_industry_id,
      ];
      console.log(e.place_data.city_code, e.place_data.district_code);
      this.place_data = [
        e.place_data.city_code,
        e.place_data.district_code + "",
      ];
      this.is_seed = e.is_seed == 1 ? true : false;

      this.isduihuan = e.isduihuan == 1 ? true : false;

      this.is_gl_agent= e.is_gl_agent == 1 ? true : false;

      console.log("is_gl_agent = ", e.is_gl_agent, this.is_gl_agent);
      console.log(this.formamend);

      //获取套餐组
      get_user_group({}).then((res) => {
        if (res.code === 0) {
          console.log("获取套餐组");
          console.log(res.data);

          this.group_list = res.data;
        }
      });
    },

    amend() {
      console.log("dddddddddd kkkkk");
      console.log(this.is_seed);
      console.log(this.isduihuan);

      if (this.activeName == "essentialInformation") {
        this.formamend;
        this.formamend.province_id = this.place_data[0];
        this.formamend.city_id = this.place_data[1];
        this.formamend.district_id = this.place_data[2];

        this.formamend.industry_id = this.industry_name[1];

        let update_data = JSON.stringify(this.formamend);

        let obj = {
          user_detail_id: this.user_detail_id,
          update_data,
        };
        amend_user(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}
</style>
